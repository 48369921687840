@import '../../../global-styles/variables';

@mixin amount {
  font-weight: bold;
  font-size: 24px;
  line-height: 28px;
}

.data-field {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin: 12% 0px 0px 0px;

  .optional {
    color: $form-subtext-color;
  }

  .text {
    margin: 6px 0px 0px 0px;
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: $form-subtext-color;
  }

  .amount {
    @include amount();
  }

  .input-amount {
    @include amount();
    // border-bottom-color: $primary-colour;
  }

  .input-optional {
    border-bottom-color: $input-form-border-color;
  }

  .input-invalid {
    border-bottom-color: #fa755a !important;
  }

  #data-field-error {
    margin-top: 8px;
    color: #a2021f;
    font-size: 12px;
  }
}
