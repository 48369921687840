#success-bg {
  background: linear-gradient(180deg, #99ec13 0%, #008646 100%);
}

#email-button {
  border-color: white;
  color: white;
}

.result-title {
  margin-top: 30px;
  margin-bottom: 30px;
  font-size: 30px;
  line-height: 35px;
  color: white;
}

.result-text {
  color: white;
}

.failure-title {
  margin-top: 10px;
  margin-bottom: 30px;
  font-size: 30px;
  line-height: 35px;
}

.failure-text {
  color: #dd0a30;
}
