/*
 * Gloabl Colour Pallet for Naby
 */

$primary-colour: #FC4C02;
$secondary-colour: #bbc2ca;
$primary-payee-colour: #1b9fb1;

$muted-font-color-high-opacity: #3d3d3db3;
$muted-font-color-half-opacity: #3d3d3d88;
$muted-font-color-no-opacity: #3d3d3d;

$error-screen-heading-color: #454545;
$error-background-color: #d40003;
$error-screen-message-color: #aa0419;

$payment-errors-color: white;

$naby-dark-blue: #006a78;
$naby-light-blue: #53b9c7;

// One off colours
$text-link-color: #5ec2cf;
$payment-screen-heading-colour: #068290;
$payment-screen-payee-username-colour: #575757;
$payment-amount-picker-form-font-colour: #35353588;

// Colours for forms
$form-header-color: #323643;
$form-subtext-color: #7a7a7a;

$input-form-border-color: #e6e6e6;
$input-label-muted-text-color: #7a7a7a;

// Quick Pay Amounts
$quick-pay-amount-button-text-color: white;
$quick-pay-amount-button-background-color: #bbc2ca;

$text-default: #7a7a7a;
$text-secondary: #575757;
$text-dark: #3d3d3d;
