@import '../../global-styles/variables';

.card-section {
  display: flex;
  flex: 1;
  flex-direction: column;
}

.card-number {
  align-self: stretch;
  margin-top: 10%;
}

.horizontal-container {
  display: flex;
  align-self: stretch;
  margin-top: 10%;
}

.element {
  flex: 1;
}

.StripeInput {
  height: 24px;
  margin-top: 8px;
  border-style: solid;
  border-width: 0px 0px 1px 0px;
  border-color: #e6e6e6;
}

.StripeElement--focus {
  border-width: 0px 0px 1px 0px;
  border-color: $primary-colour;
}

.StripeElement--invalid {
  border-color: #fa755a;
}

.StripeElement--webkit-autofill {
  background-color: #fefde5 !important;
}
